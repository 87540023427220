import { useDispatch, useSelector } from "react-redux";
import Search from "../../components/Search";
import CardLoading from "../../components/CardLoading";
import { useEffect, useState } from "react";
import OrganizationListCard from "./card/OrganizationListCard";
import { fetchOrganizationCard } from "../../features/fetch/fetchSlice";
import { useNavigate } from "react-router-dom";
import { fetchOrganizationToken } from "../../features/auth/authSlice";
import toast from "react-hot-toast";

export default function OrganizationList() {
  const { isLoading, organizationCardData } = useSelector(
    (state) => state.fetch
  );
  const { organizationActive } = useSelector(
    (state) => state.obj
  );
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token"); 
  const [searchInput, setsearchInput] = useState("");

  const handleSearch = (val) => {};

  async function fetchOrganizationCardData() {
    await dispatch(
      fetchOrganizationCard({
        url: "api/v1/admin/get-dynamicOrganisations",
      })
    );
  }
 
  async function fetchOrganizationTokenData() {
     const res =  await dispatch(fetchOrganizationToken()); 
     if (res?.payload.success){
        fetchOrganizationCardData()
     }
   
  }

 

  useEffect(() => {
    if(organizationActive){
      fetchOrganizationTokenData(); 
    } else{
      fetchOrganizationCardData()
    }
  }, []);

  if (isLoading) return <CardLoading />;

  return (
    <>
      <div className="flex flex-col w-full bg-white rounded-[1.25rem] h-full p-[2.5rem]">
        <>
          <div className="w-[16.8125rem]">
            <Search
              searchInput={searchInput}
              handleSearch={handleSearch}
              text={"Search organization"}
            />
          </div>

          <div className="flex flex-shrink flex-wrap h-auto w-full mt-[1.25rem] gap-x-[2.44rem] gap-y-[2rem]">
            {organizationCardData?.length > 0 &&
              organizationCardData?.map((item) => (
                <div className="h-full">
                  <OrganizationListCard item={item} />
                </div>
              ))}
          </div>
        </>
      </div>
    </>
  );
}
