import { Spin } from 'antd';
import React from 'react';

export default function CardLoading() {
	return (
		<div
			style={{
				border: '1px solid var(--Tile-stroke, #E2E9EE)',
				background: '#fff',
			}}
			className='w-full mt-[1.5rem] h-[13.75rem] rounded-[1.25rem] flex justify-center items-center'>
                <Spin className='blu_spin' size="large" />
            </div>
	);
}
