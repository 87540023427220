import React, { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { save } from "../../../../features/save/saveSlice";
import {  paybillStatusData, tariffData } from "../../../../data";

const { TextArea } = Input;
const AddTariffNegotiatedModal = ({ open, handleCancel, handleFetchData, prodd }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null); 

  const dispatch = useDispatch();
  const navigate = useNavigate();

const handleCancelForm =async ()=>{
 await handleCancel()
   form.resetFields();
}
  const { saving } = useSelector((state) => state.save);

  const onFinish = async (data) => {
    data.tarUid =  prodd ? prodd.tarUid :null;
    const res = await dispatch(
      save({
        ...data,

        url: "api/v1/payments/tariff-negotiated/save-tariff-negotiated",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleFetchData();
      await handleCancelForm();
       form.resetFields();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };
 
  useEffect(() => { 
    if (prodd) {  
      form.setFieldsValue(prodd);  
    } else {  
      form.resetFields();  
    }
  }, [prodd]);
 
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={prodd ? `Edit ${prodd?.tarType}` : "Add tariff negotiated details"}
        open={open}
        onOk={onFinish}
        onCancel={handleCancelForm}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
          // initialValues={prodd}  
        >
          <Form.Item
            name="tarFrom"
            label="From"
            rules={[
              {
                required: true,
                message: "Enter From",
              },
            ]}
          >
            <InputNumber
              min={0}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              className="input flex"
            />
          </Form.Item>

          <Form.Item
            name="tarTo"
            label="To"
            rules={[
              {
                required: true,
                message: "Enter to",
              },
            ]}
          >
            <InputNumber
              min={0}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              className="input flex"
            />
          </Form.Item>

          <Form.Item
            name="tarType"
            className=""
            label={<span>Type</span>}
            rules={[
              {
                required: true,
                message: "Please select type",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                tariffData?.length > 0 &&
                tariffData?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {}}
            />
          </Form.Item>

        
          <Form.Item
            name="tarCharges"
            label="Charges"
            rules={[
              {
                required: true,
                message: "Enter Charges",
              },
            ]}
          >
            <InputNumber
              min={0}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              className="input flex"
            />
          </Form.Item>
          <Form.Item
            name="tarDesc"
            label="Description"
            rules={[
              {
                required: true,
                message: "Enter Description",
              },
            ]}
          >
            <TextArea rows={4} className="input-textarea" />
          </Form.Item>
        

          {/* <Form.Item
            name="tarDesc"
            label="Description"
            rules={[
              {
                required: true,
                message: "Enter Description",
              },
            ]}
          >
            <TextArea rows={4} className="input-textarea" />
          </Form.Item>

          <Form.Item
            name="tarCode"
            label="Code"
            rules={[
              {
                required: true,
                message: "Enter Code",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name="tarStatus"
            className=""
            label={<span>Status</span>}
            rules={[
              {
                required: true,
                message: "Please select Status",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                paybillStatusData?.length > 0 &&
                paybillStatusData?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {}}
            />
          </Form.Item>

          <Form.Item
            name="tarMpesaCharge"
            label="Mpesa Charges"
            rules={[
              {
                required: true,
                message: "Enter Mpesa Charges",
              },
            ]}
          >
            <InputNumber
              min={0}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              className="input flex"
            />
          </Form.Item> */}

          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancel}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Create"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddTariffNegotiatedModal;
