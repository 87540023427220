import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    collapsed: false,
    stateOpenKeys: ['1', '2'],
    stateSelectedKeys: ['1', '2'],
};

export const setupSlice = createSlice({
    name: 'setup',
    initialState,
    reducers: {
        toggleCollapse: (state) => {
            state.collapsed = !state.collapsed;
        },
        handleOpenKeys: (state, action) => {
            state.stateOpenKeys = action.payload;
        },
		handleSelectedKeys: (state, action) => {
            state.stateSelectedKeys = action.payload;
        },
    },
});

export default setupSlice.reducer;
export const { toggleCollapse, handleOpenKeys, handleSelectedKeys } = setupSlice.actions;
