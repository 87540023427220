import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, dateForHumans, formatMoney, numberWithCommas } from "../../utils";
import { useNavigate } from "react-router-dom";
import thermometer from "../../assets/svg/thermometer.svg";
import { setWalletObj } from "../../features/obj/objSlice";
import EmtyCard from "../../components/EmtyCard";
import BreadCrumb from "../../layout/BreadCrumb";
import CardLoading from "../../components/CardLoading";
import { fetchSubwallets } from "../../features/fetch/fetchSlice";

function Subwallet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeObj } = useSelector((state) => state.obj);
  const { subwalletData,loading } = useSelector((state) => state.fetch);
  const { activeOrganization } = useSelector((state) => state.obj);

  const handleViewWallet = async (item) => {
    await dispatch(setWalletObj(item));
    await navigate("/wallet-view");
  };

  const handleBack = async (item) => {
    navigate("/wallets");
  };
  const breadList = [
    {
      title: (
        <div
          className="flex items-center gap-[.25rem] cursor-pointer"
          onClick={handleBack}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Wallets</span>
        </div>
      ),
    },
    {
      title: capitalize("Sub Wallets"),
    },
  ];

 
  async function fetchSubwalletsData() {
    await dispatch(
      fetchSubwallets({ 
        url: `api/v1/wallet/subWallet`,
        walParentId:activeObj?.walUid,
      })

    );
  } 

  useEffect(() => {
    fetchSubwalletsData();
  }, []); 
  if (loading) return <CardLoading />;

  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="white_card justify-between items-center ">
        <BreadCrumb
          breadList={breadList}
          header={"Sub Wallets"}
          info={`View all sub wallets under the ${activeObj?.walType} wallet`}
        />
      </div>
      <div className="white_card flex-col min-h-[35rem] h-full mt-[1.25rem]">
        <div className="w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]">
          <span className="heading_4">Sub Wallets under {activeOrganization?.orgName} Organization</span>
        </div>

        <div>
          {activeObj && Object?.keys(activeObj)?.length > 0 ? (
            <>
              <div className="account_card_alt !w-[90%] mt-[1.69rem]">
                <div className="w-full flex justify-between items-center pointer">
                  <div className="flex flex-col">
                    <span className="label_1">{activeObj?.walName}</span>
                    <span className="paragraph_1">
                      {formatMoney(activeObj?.walAmount)}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="view_more_text"
                    onClick={() => handleViewWallet(activeObj)}
                  >
                    View details
                  </button>
                </div>
              </div>
            </>
          ) : (
            <EmtyCard title="No main Wallet data available" />
          )}

          {subwalletData &&
            subwalletData?.map((item, key) => (
              <div>
                <div className="ml-[1.5rem]">
                  <img src={thermometer} alt="account-line" />
                </div>

                <div className="account_card_alt !w-[90%] mt-[.44rem]">
                  <div className="flex items-center justify-between w-full">
                    <div>
                      <div className="w-full flex justify-between items-center">
                        <div className="flex flex-col">
                          <span className="label_1">{item?.walName}</span>
                          <span className="paragraph_1">
                            {" "}
                            {item?.walStatus}
                          </span>
                        </div>
                      </div>

                      <span className="paragraph_2 !font-bold !text-[#141414] mt-[1.5rem]">
                        Summary
                      </span>
                      <div className="w-full flex items-center gap-[.75rem]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <g filter="url(#filter0_d_1248_13000)">
                            <circle cx="8" cy="4.5" r="4" fill="#34B849" />
                          </g>
                          <defs>
                            <filter
                              id="filter0_d_1248_13000"
                              x="0"
                              y="0.5"
                              width="16"
                              height="16"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1248_13000"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1248_13000"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                        <span className="paragraph_1">
                          Wallet Amount | {item?.walCurrency}{" "}
                          {numberWithCommas(item?.walAmount)}
                        </span>
                      </div>
                      <div className="w-full flex items-center gap-[.75rem]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <g filter="url(#filter0_d_1248_12913)">
                            <circle cx="8" cy="4.5" r="4" fill="#C72C3B" />
                          </g>
                          <defs>
                            <filter
                              id="filter0_d_1248_12913"
                              x="0"
                              y="0.5"
                              width="16"
                              height="16"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1248_12913"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1248_12913"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                        <span className="paragraph_1">
                          Created Date |{dateForHumans(item?.walCreatedAt)}
                        </span>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="view_more_text flex cursor-pointer  justify-end"
                      onClick={() => handleViewWallet(item)}
                    >
                      View details
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Subwallet;
