import React from 'react'
import img2 from '../../assets/img/img2.png';
import img3 from '../../assets/img/img3.png';

function RightSide() {
  return (
    <div className="hidden lg:flex w-full login-pic px-[3.19rem] py-[4.5rem] flex-col min-h-screen justify-between">
    <div>
        <div className="typography">Seamless payment management</div>
        <div className="typography2 w-[26.75rem] mt-[1rem]">The fastest way to manage payments across your departments</div>
        <div className="mt-[3.87rem]">
            <img src={img2} alt="img2" className="w-full max-w-[26.75rem] object-contain" />
        </div>
        <div className="px-[10.38rem] mt-[-4rem]">
            <img src={img3} alt="img3" className="w-full max-w-[20rem] object-contain" />
        </div>
    </div>
    <div className="typography-footer mb-[2rem] flex justify-end">Powered by Smart People Africa</div>
</div>
  )
}

export default RightSide