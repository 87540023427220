import { Dropdown, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";  
import eyeSvg from "../../assets/svg/eye.svg";
import {
    fetchCustomers, 
} from "../../features/fetch/fetchSlice";
import { capitalize, dateForHumans, timeAmPm } from "../../utils";
import BreadCrumb from "../../layout/BreadCrumb";
import { setActiveCustomer, setActiveOrganization } from "../../features/obj/objSlice";
import FunnelSimple from "../../assets/svg/FunnelSimple.svg"; 
import CardLoading from "../../components/CardLoading"; 
import useModalToggle from "../../custom_hooks/useModalToggle";

export default function CustomerTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, customersCount, customers } = useSelector(
    (state) => state.fetch
  );
  
  const {activeOrganization} =  useSelector((state)=>state.obj)

  const [searchText, setSearchText] = useState("");

  const { open, handleCancel, handleOpen } = useModalToggle();

  const [filters, setFilters] = useState([]);
  const [formData, setFormData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModalFilter = () => {
    handleOpen();
  };

  const handleClearFilters = async () => {
    await setFormData({});
    await fetchCustomersData();
    await setFilters([]);
  };

  const [prodd, setProdd] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };

  const handleDelete = async () => {};

  const handleView = async (item) => { 
    await dispatch(setActiveCustomer(item));
    await navigate("/customer-details");
  };

  const settingItems = [
    {
      key: "1",
      label: (
        <Link className="flex  text-[16px] font-sans  !text-lightBlack">
          Edit
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={showModalDelete}
          className="flex  text-[16px] font-sans   !text-lightBlack"
        >
          Delete
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "First Name",
      dataIndex: "custFirstname",
      sorter: (a, b) => a.custFirstname.localeCompare(b.custFirstname),
    },
    {
      title: "Last Name",
      dataIndex: "custLastname",
      sorter: (a, b) => a.custLastname.localeCompare(b.custLastname),
    },

    {
      title: "Email Address",
      dataIndex: "custEmail",
      sorter: (a, b) => a.custEmail.localeCompare(b.custEmail),
    },
    {
      title: "Mobile Number",
      dataIndex: "custMobileNumber",
      sorter: (a, b) => a.custMobileNumber.localeCompare(b.custMobileNumber),
    },
    {
      title: "National ID",
      dataIndex: "custNationalId",
      sorter: (a, b) => a.custNationalId.localeCompare(b.custNationalId),
    },
    {
      title: "Status",
      render: (item) => {
        return (
          <div className="flex items-center text-center gap-x-2">
            <span
              className={`w-[10px] h-[10px] rounded-full ${
                item?.custStatus === "ACTIVE" ? "bg-green" : "bg-red"
              }`}
            ></span>
            {item?.custStatus}
          </div>
        );
      },
      sorter: (a, b) => a.custStatus.localeCompare(b.custStatus),
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "View",
      render: (item) => (
        <button onClick={() => handleView(item)}>
          <img src={eyeSvg} alt="eyeSvg" />
        </button>
      ),
    },
  ];
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchCustomersData(page, size) {
    await dispatch(
        fetchCustomers({
          url: "api/v1/customers/fetch-customers",
          limit: size ?? pageSize,
          start: page ?? pageIndex,
          custNationalId: formData?.custNationalId,
          custMobileNumber: formData?.custMobileNumber,
          custStatus: formData?.custStatus,
          custOrgId:activeOrganization?.orgUid,
        })
      );
  }
  
 
  useEffect(() => {
      fetchCustomersData();
  }, []);


 const handleHome=async()=>{ 
  await navigate('/organization-customerApproval')
 }


  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem] cursor-pointer" onClick={handleHome}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Customer Approvals</span>
        </div>
      ), 
    },
    {
      title: capitalize("Customer Details"),
    },
  ]; 
  if (loading) return <CardLoading />;

  return (
    <div className="w-full h-full overflow-y-scroll">
       <div className="white_card justify-between items-center ">
        <BreadCrumb
          breadList={breadList}
          header={"Customer List"}
          info={`Manage all ${activeOrganization?.orgName} customers here`}
        />
      </div>
        <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
          <div className="flex flex-col w-full lg:px-10 px-3 ">
            <h3 className="heading_4 mt-[1.25rem]">Customers List</h3>
            <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
              {/* <div className="flex items-center">
                <span>
                  {" "}
                  <button
                    onClick={showModalFilter}
                    type="button"
                    className={`bg-transparent paragraph_1 flex items-center gap-x-1 ${
                      Object?.keys(formData)?.length > 0
                        ? "!text-[#5688E5]"
                        : "inherit"
                    }`}
                  >
                    <img src={FunnelSimple} alt="FunnelSimple" />
                    Filters
                  </button>
                </span>
                {Object?.keys(formData)?.length > 0 && (
                  <span className="flex items-center text-[#5688E5] cursor-pointer ml-1">
                    :{Object?.keys(formData)?.length}
                    <img src={svg38} alt="svg38" onClick={handleClearFilters} />
                  </span>
                )}
              </div> */}
            </div>
            <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
              <section>
                <Table
                  rowSelection={false}
                  className="mt-[1.31rem] w-full"
                  scroll={{
                    x: 1200,
                  }}
                  rowKey={(record) => record?.custId}
                  columns={columns}
                  dataSource={customers}
                  loading={loading}
                  pagination={{
                    position: ["bottomCenter"],
                    current: pageIndex + 1,
                    total: customersCount,
                    pageSize: pageSize,
                    onChange: (page, size) => {
                      setPageIndex(page - 1);
                      setPageSize(size);
                      fetchCustomersData(page - 1, size);
                    },
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                />
              </section>
            </div>
          </div>
        </div>
       

      {/* <CustomerFilterModal
        isModalOpen={open}
        handleCancel={handleCancel}
        formData={formData}
        setFormData={setFormData}
        filters={filters}
        setFilters={setFilters}
      /> */}
    </div>
  );
}
