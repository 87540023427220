import { Descriptions } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { dateForHumans } from "../../../utils";

function CustomerBasicDetails() {
  const { activeCustomer } = useSelector((state) => state.obj);

  const items = [
    {
      key: "0",
      label: "First Name",
      children: activeCustomer?.custFirstname,
    },
    {
      key: "1",
      label: "Last Name",
      children: activeCustomer?.custLastname,
    },
    {
      key: "12",
      label: "Middle Name",
      children: activeCustomer?.custMiddleName,
    },
    {
      key: "2",
      label: "National ID",
      children: activeCustomer?.custNationalId,
    },
    {
      key: "3",
      label: "Email",
      children: activeCustomer?.custEmail,
    },
    {
      key: "4",
      label: "Mobile Number",
      children: activeCustomer?.custMobileNumber,
    },

    {
      key: "5",
      label: "Asylum Date Of Issuance",
      children: (
        <div>{dateForHumans(activeCustomer?.custAsylumDateOfIssuance)}</div>
      ),
    },
    {
      key: "6",
      label: "Asylum paper expiry",
      children: (
        <div>{dateForHumans(activeCustomer?.custAsylumPaperExpiry)}</div>
      ),
    },

    {
      key: "7",
      label: "Asylum Reference Number",
      children: activeCustomer?.custAsylumRefNumber,
    },
    {
      key: "8",
      label: "Birth City",
      children: activeCustomer?.custBirthCity,
    },
    {
      key: "9",
      label: "Birth Country",
      children: activeCustomer?.custBirthCountry,
    },
    {
      key: "10",
      label: "Gender",
      children: activeCustomer?.custGender,
    },
    {
      key: "11",
      label: "Marital Status",
      children: activeCustomer?.custMaritalStatus,
    },
    {
      key: "13",
      label: "National Identity Date of Issuance",
      children: (
        <div>
          {dateForHumans(activeCustomer?.custNationalIdentityDateOfIssuance)}
        </div>
      ),
    },
    {
      key: "14",
      label: "Passport Country",
      children: activeCustomer?.custPassportCountry,
    },
    {
      key: "15",
      label: "Passport Date of Issuance",
      children: (
        <div>{dateForHumans(activeCustomer?.custPassportDateOfIssuance)}</div>
      ),
    },
    {
      key: "16",
      label: "Passport Expiry Date",
      children: <div>{dateForHumans(activeCustomer?.custPassportExpiry)}</div>,
    },

    {
      key: "17",
      label: "Passport Number",
      children: activeCustomer?.custPassportNumber,
    },
    {
      key: "18",
      label: "Passport place of issuance",
      children: activeCustomer?.custPassportPlaceOfIssuance,
    },
    {
      key: "19",
      label: "School Name",
      children: activeCustomer?.custSchoolName,
    },
    {
      key: "20",
      label: "Mobile Number 2",
      children: activeCustomer?.custPhone2,
    },
    {
      key: "21",
      label: "Title",
      children: activeCustomer?.custTitle,
    },
  ];

  return (
    <div> 
      <Descriptions
        column={2}
        colon={true}
        title="Customer Basic Details"
        items={items}
      />
    </div>
  );
}

export default CustomerBasicDetails;
