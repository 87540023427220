export default function ProTips() {
	return (
		<>
			<div className='w-full min-h-[8rem] h-full bg-[#E8F0FF] flex justify-between items-center pl-[2.5rem] py-[.25rem] pr-[1.5rem] rounded-[1.25rem]'>
				<div className='w-full flex flex-col gap-[.75rem]'>
					<span className='label_1'>
						Pro tip | Grow your investments
					</span>
					<span className='paragraph_1'>
						You can select which organization you want to view
					</span>
				</div>

				{/* <div className='w-fit'>
					<button disabled className='cstm-btn-2 !bg-[#17225F]'>Invest</button>
				</div> */}
			</div>
		</>
	);
}
