import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { logout } from "./features/auth/authSlice"; 
import internetloss from './assets/img/internet2.jpg' 
import Header from "./layout/Header";

export default function Root() {
  const { isActive } = useSelector((state) => state.auth);

  const token = localStorage.getItem('token'); 

  const [tokenExpired, setTokenExpired] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loc = useLocation();
  const isTokenExpired = (token) => { 
    if (!token) return true;

    const decodedToken = token?.length && jwtDecode(token);
    const currentTime = Date.now() / 1000;

    return decodedToken.exp < currentTime;
  };


  useEffect(() => {
    if (!token) { 
      setTokenExpired(true);
      dispatch(logout()); 
      navigate("/login");
      return;
    }

    if (isTokenExpired(token)) { 
      setTokenExpired(true);
      dispatch(logout()); 
      navigate("/login");
    } else {
      setTokenExpired(false);
    }
  }, [token, dispatch, navigate]);


  if (!navigator.onLine) {
    return (
      <div className="w-full h-full overflow-y-scroll">
        <div 
          className='flex flex-col items-center justify-center text-center py-0 lg:px-[50px] mx-[50px]'
        >
          <h1 className="text-red font-[700]" >
            Oops! Something went wrong...
          </h1>
          <p>You appear to be offline. Turn on your Wi-Fi to continue</p>
          <img
            src={internetloss}
            className="object-cover w-full h-full" 
          />
        </div>
      </div>
    );
  }
 
  return (
    <div className="flex flex-col w-full ">
      {isActive &&
        !tokenExpired &&
        window.location.href != "/" &&
        loc?.pathname != "/" && <Header />}

      <div className="flex flex-col items-start w-full h-full">
        <Outlet />
      </div>
    </div>
  );
}
