import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeOrganization: {},
  organizationActive: false,
  activeCustomer: {},
  customerActive: false,
  activeObj: {},
  walletObj:{},
};

export const objSlice = createSlice({
  name: "obj",
  initialState,
  reducers: {
    setActiveOrganization: (state, action) => {
      state.activeOrganization = action.payload;
      state.organizationActive = true;
    },
    setActiveCustomer: (state, action) => {
      state.activeCustomer = action.payload;
      state.customerActive = true;
    },
    setActiveObj: (state, action) => {
      state.activeObj = action.payload;
    },
    setWalletObj: (state, action) => {
        state.walletObj = action.payload;
    },
    clearObj: () => {
      return { ...initialState };
    },
  },
});

export default objSlice.reducer;
export const {
  clearObj,
  setActiveOrganization,
  setActiveCustomer,
  setActiveObj,
  setWalletObj,
} = objSlice.actions;
