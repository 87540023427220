import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";
import qs from 'qs';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  glbLoading: false,
  sideMenuCollapsed: false, 
  loading: false, 
  menuLoading: false,
  menuData: [],

  refetch:false,
  refetchKey:'', 

  refetch1:false,
  refetchKey1:'', 
};

export const downloadFiles = createAsyncThunk(
  "graduate/downloadFiles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${url}/api/v2/onboard/view-file?attrType=${data?.attrType ?? ''}&fileName=${data?.fileName ?? ''}`,
        { responseType: 'blob' } 
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchMenu = createAsyncThunk("menuSlice/fetchMenu", async () => {
  const res = await axiosInstance
    .get(`${url}/api/v1/menu`)
    .then((res) => res.data?.data?.result);
  return res;
});


export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    handleSideMenuCollapse: (state, action) => {
      state.sideMenuCollapsed = action.payload;
    },
      

    setRefetchKey: (state, action) => {
      state.refetchKey = action.payload;
    },
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    },
    

    setRefetchKey1: (state, action) => {
      state.refetchKey1 = action.payload;
    },
    setRefetch1:(state)=>{
      state.refetch1 = !state.refetch1;
    },

  },

  extraReducers: (builder) => {
    builder

      .addCase(downloadFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadFiles.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(downloadFiles.rejected, (state) => {
        state.loading = false;
      })
 
      .addCase(fetchMenu.pending, (state) => {
        state.menuLoading = true;
      })
      .addCase(fetchMenu.fulfilled, (state, action) => {
        state.menuLoading = false;
        state.menuData = action.payload;
      })
      .addCase(fetchMenu.rejected, (state) => {
        state.menuLoading = false;
        state.menuData = [];
      })
      
      
  },
});

export default globalSlice.reducer;
export const {
  handleSideMenuCollapse, 
  setRefetchKey,
  setRefetch,
  setRefetchKey1,
  setRefetch1

} = globalSlice.actions;
