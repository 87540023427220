import React from 'react';
import { useSelector } from 'react-redux';
import ProTips from './ProTips';
import OrganizationList from './OrganizationList';
import BreadCrumb from '../../layout/BreadCrumb';

function Home() {
    const { user } = useSelector((state) => state.auth);
  
    return (
        <div className='w-full h-full overflow-y-scroll'>
            <div className="justify-between items-center white_card ">
                <BreadCrumb header={`Welcome ${user?.userName}`} info={'Manage all your organizations below'} />
            </div>

            <div className='mt-[1.25rem]'>
                <ProTips />
            </div>

            <div className='mt-[1.25rem]'>
                <OrganizationList />
            </div>
        </div>
    );
}

export default Home;
