import React, { useEffect } from "react"; 
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux"; 
import gridSvg from "../assets/svg/user.svg";
import { useNavigate } from "react-router-dom";
 

const menuData = [
  {
    mnId: "1",
    mnLink: "dashboard-main",
    mnName: "dashboard",
    mnOwner: "ALL",
    mnParentId: null,
    mnIcons: null,
    children: [],
  },  
 
];

const icons = {
  gridSvg, 
};
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
 
export default function SideBarClosed() {
  const { sideMenuCollapsed } = useSelector((state) => state.global); 
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const generateMenuItems = (menuData) => {
    if (user?.changePassword === true || user?.changePassword === null) {
      return [];
    }
    const mapItems = (items, isChild) => {
      return items?.map((item) => {
        const iconSrc = icons[item?.mnIcons] || gridSvg;
        return getItem(
          item?.mnName.charAt(0).toUpperCase() + item?.mnName.slice(1),
          item?.mnLink,
          !isChild ? <img src={iconSrc} alt={item?.mnIcons} /> : null,
          item?.children && item?.children?.length > 0
            ? mapItems(item?.children, true)
            : null
        );
      });
    };
    if (!menuData || menuData?.length === 0) {
      return [];
    }
   
    return mapItems(menuData);
  };
  const items = [
    ...generateMenuItems(menuData),

    {
      type: "divider",
    },
  ];

  const handleNavigation = async (e) => {
    await navigate(e?.key);
  };
  useEffect(() => {}, [sideMenuCollapsed]);

 
  return (
    <>
      <div
        style={{
          borderInlineEnd: "1px solid rgba(5, 5, 5, 0.06)",
        }}
        className="flex flex-col h-full bg-[#f4f5f7]"
      >
        <Menu
          onClick={handleNavigation}
          defaultSelectedKeys={["dash", "/dashboard"]}
          defaultOpenKeys={[]}
          mode="inline"
          theme="light"
          inlineCollapsed={sideMenuCollapsed} 
          items={items}
        />
      </div>
    </>
  );
}
