import { Descriptions, Table } from "antd";
import React, { useEffect } from "react";
import { fetchOrganizationAdmin } from "../../../features/fetch/fetchSlice";
import { useDispatch, useSelector } from "react-redux";
import CardLoading from "../../../components/CardLoading";

function AdminDetails() {
  const { organizationAdminData ,loading} = useSelector((state) => state.fetch);
  const { activeOrganization } = useSelector((state) => state.obj);
  const { refetch, refetchKey } = useSelector((state) => state.global);

  const dispatch = useDispatch();
 
  async function fetchOrganizationAdminData() {
    await dispatch(
      fetchOrganizationAdmin({
        orgId: activeOrganization?.orgUid,
      })
    );
  }

  const items = [
    {
      key: "0",
      label: "First Name",
      children: organizationAdminData?.usrFirstname,
    },
    {
      key: "1",
      label: "Last Name",
      children: organizationAdminData?.usrLastname,
    },
    {
      key: "2",
      label: "National ID",
      children: organizationAdminData?.usrNationalId,
    },
    {
      key: "3",
      label: "Email",
      children: organizationAdminData?.usrEmail,
    },
    {
      key: "4",
      label: "Mobile Number",
      children: organizationAdminData?.usrMobileNumber,
    },
  ];

  useEffect(() => {
    if (refetchKey == 1) {
      fetchOrganizationAdminData();
    }
  }, [refetchKey]);

  useEffect(()=>{
      fetchOrganizationAdminData()
  },[])

  if (loading) return <CardLoading />;
  return (
    <div className="w-full mt-[1.25rem] ">
      <Descriptions
        column={2}
        colon={false}
        title="Admin Details"
        items={items}
      />
    </div>
  );
}

export default AdminDetails;
