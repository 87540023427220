import { Tabs } from 'antd';
import React, { useState } from 'react';
import { setRefetch, setRefetchKey } from '../../../../features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import BreadCrumb from '../../../../layout/BreadCrumb'; 
import { capitalize } from '../../../../utils';
import AdminDetails from './AdminDetails'; 
import KyuDocumentsTab from './KyuDocumentsTab';
import KybDocumentsTab from './KybDocumentsTab'; 

function OrganizationApprovalDetails() {
    const dispatch  =  useDispatch()
    const navigate = useNavigate()

    const {activeOrganization} =  useSelector((state)=>state.obj)

    const [active, setactive] = useState('1');
    async function refetching(activeKey) {
        setactive(activeKey);
        await dispatch(setRefetch());
        await dispatch(setRefetchKey(activeKey));
    }

    const tabConfigurations = [
        {
            label: (
                <span className="flex items-center">
                    <span className=" ">Admin User</span>
                </span>
            ),
            key: '1',
            children: <AdminDetails/>,
        },
        {
            label: (
                <span className="flex items-center">
                    <span className=" ">KYU Documents</span>
                </span>
            ),
            key: '2', 
            children: <KyuDocumentsTab active={active} setactive={setactive}/>,
        },

        {
            label: (
                <span className="flex items-center">
                    <span className=" ">KYB Documents</span>
                </span>
            ),
            key: '3', 
            children: <KybDocumentsTab setActive={setactive}/>,
        },

        
    ];

    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Organizations</span>
                </div>
            ),
            href: '/#/org-approval',
        },
        {
            title: capitalize('Organization Details'),
        },
    ];

    return (
        <div className='w-full h-full overflow-y-scroll'>
         <div className="justify-between items-center white_card">
                <BreadCrumb breadList={breadList} header={'Organization Summary'} info={`${activeOrganization?.orgName} Details`} />
            </div>
            <div className="mt-[1.25rem] white_card w-full min-h-[100vh]">
                <Tabs items={tabConfigurations} defaultActiveKey="1" activeKey={active} onChange={refetching} className="activity-tab-wrap activity-tab activity-bar" />
            </div>
        </div>
    );
}

export default OrganizationApprovalDetails;
