import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate, 
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "./layout/SideBar"; 
import { jwtDecode } from "jwt-decode";
import { handleUser, logout,   } from "./features/auth/authSlice";
import { useEffect } from "react";
import axiosInstance from "./instance";
 
export const ProtectedRoute = ({ role }) => {
 
  const { isActive } = useSelector((state) => state.auth);

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem('token'); 
  console.log("token",token)
	axiosInstance.defaults.headers.common = { Authorization: `Bearer ${token}` };

  async function handleDecodeToken() {
		try {
			let tokenObj = jwtDecode(token);
			await dispatch(handleUser(tokenObj));
		} catch (error) {
			await dispatch(handleUser({}));
		}
	}

  async function handleInit() {
		if (token) {
			await handleDecodeToken(); 
		}
	}

  const isTokenExpired = (token) => {
    if (!token) return true;

    const decodedToken = token?.length && jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken?.exp < currentTime) {
      return true;
    }

    return false;
  };
  
 
  async function handleLogout() {  
    await dispatch(logout()); 
    await localStorage.clear(); 
    await navigate("/login"); 
  }
 
  

  useEffect(() => {
    if (isTokenExpired(token)) {
      handleLogout();
    }
  }, [token, pathname]);

  

	useEffect(() => {
		axiosInstance.defaults.headers.common = {
			Authorization: `Bearer ${token}`,
		};
		handleInit();
	}, [token, isActive]);

 
  if (!isActive && !token) {
		return <Navigate to='/login' replace />;
	} 

  return (
    <>
      
        <div className="flex items-start justify-start w-full h-[92vh] my-3">
          <SideBar />
            <Outlet /> 
       
        </div> 
    </>
  );
};
