import React, { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { save } from "../../../../features/save/saveSlice"; 
import { paybillData, paybillStatusData } from "../../../../data";

const { TextArea } = Input; 
const AddPaybillModal = ({ open, handleCancel, handleFetchData, prodd }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);

  const onFinish = async (data) => { 
    data.pbUid =  prodd.pbUid ?? null;
    const res = await dispatch(
      save({
        ...data,

        url: "api/v1/payments/save-paybill",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleFetchData(); 
      await handleCancel();
      await form.resetFields();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };
  
  useEffect(() => {
    if (prodd) {
      form.setFieldsValue(prodd);
    } else {
      form.resetFields();
    }
  }, [prodd, form]);

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={prodd ? `Edit ${prodd?.pbName}` : "Add paybill details"}
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
          // initialValues={prodd}
        >
          <Form.Item
            name="pbPaybill"
            label="Paybill"
            rules={[
              {
                required: true,
                message: "Enter Paybill",
              },
            ]}
          >
            <InputNumber
              min={0}
              // formatter={(value) =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              // }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              className="input flex"
            />
          </Form.Item>

          <Form.Item
            name="pbName"
            label="Name"
            rules={[
              {
                required: true,
                message: "Enter Paybill Name",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name="pbType"
            className=""
            label={<span>Type</span>}
            rules={[
              {
                required: true,
                message: "Please select type",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                paybillData?.length > 0 &&
                paybillData?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {}}
            />
          </Form.Item>

          <Form.Item
            name="pbDesc"
            label="Description"
            rules={[
              {
                required: true,
                message: "Enter Description",
              },
          ]}
          >
             
            <TextArea rows={4} className="input-textarea" />
          </Form.Item>

          <Form.Item
            name="pbStatus"
            className=""
          label={<span>Status</span>}
            rules={[
              {
                required: true,
                message: "Please select Status",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                paybillStatusData?.length > 0 &&
                paybillStatusData?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {}}
            />
          </Form.Item>

          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancel}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Create"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddPaybillModal;
