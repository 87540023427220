import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Input, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import remove from "../../../../assets/svg/delete.svg";
import svg37 from "../../../../assets/svg/svg37.svg";
import svg25 from "../../../../assets/svg/svg25.svg";
import {
  fetchCommissions,
  fetchTariffs,
} from "../../../../features/fetch/fetchSlice";
import { tariffData } from "../../../../data";

const { TextArea } = Input;
const FilterModal = ({
  isModalOpen,
  handleCancel,
  prodd,
  formData,
  setFormData,
  filters,
  setFilters,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const items = [
    {
      label: "Name",
      key: "Name",
    },
    {
      label: "Commission Amount",
      key: "Commission Amount",
    },
    {
      label: "Payment Amount",
      key: "Payment Amount",
    },
    {
      label: "Mobile",
      key: "Mobile",
    },
  ];

  // const [filters, setFilters] = useState([]);

  const filteredItems = items.filter(
    (item) => !filters?.some((filter) => filter?.value === item?.key)
  );

  const settingItems = filteredItems?.map((item) => ({
    key: item?.key,
    label: (
      <div
        className="font-dmSans text-black font-[400] text-[19px] mt-[5%]"
        onClick={() => handleMenuItemClick(item?.key)}
      >
        {item?.label}
      </div>
    ),
  }));

  const handleMenuItemClick = (itemKey) => {
    if (itemKey === "Name") {
      const newFilter = {
        type: "Name",
        value: "Name",
      };
      setFilters([...filters, newFilter]);
    } else if (itemKey === "Commission Amount") {
      const newFilter = {
        type: "Commission Amount",
        value: "Commission Amount",
      };
      setFilters([...filters, newFilter]);
    } else if (itemKey === "Payment Amount") {
      const newFilter = {
        type: "Payment Amount",
        value: "Payment Amount",
      };
      setFilters([...filters, newFilter]);
    } else if (itemKey === "Mobile") {
      const newFilter = {
        type: "Mobile",
        value: "Mobile",
      };
      setFilters([...filters, newFilter]);
    }
  };

  const removeFilter = (index) => {
    const updatedFilters = filters?.filter((_, idx) => idx !== index);
    setFilters(updatedFilters);
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      if (filters[index].type === "Name") {
        delete updatedData.blkReceiverName;
      } else if (filters[index].type === "Commission Amount") {
        delete updatedData.comAmount;
      } else if (filters[index].type === "Payment Amount") {
        delete updatedData.blkPaymentAmount;
      } else if (filters[index].type === "Mobile") {
        delete updatedData.blkReceiverMobileNo;
      }
      return updatedData;
    });
  };

  // const [formData, setFormData] = useState();

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const [dynamicKey] = Object?.keys(formData);
  const dynamicValue = formData[dynamicKey];

  const onFinish = async (data) => {
    const res = await dispatch(
      fetchCommissions({
        blkReceiverName: formData?.blkReceiverName,
        comAmount: formData?.comAmount,
        blkPaymentAmount: formData?.blkPaymentAmount,
        blkPaymentAmount: formData?.blkPaymentAmount,
        blkReceiverMobileNo: formData?.blkReceiverMobileNo,
        url: "api/v1/commissions/fetch-commissions",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res.payload?.messages?.message);
      await handleCancel();
    } else {
      toast.error(res.payload?.messages?.message);
    }
  };

  const handleClear = async () => {
    await setFilters([]);
    await setFormData({});
    await handleCancel();
  };

  return (
    <>
      <Modal
        className="!mt-[13%] filter-modal "
        title="New Account "
        open={isModalOpen}
        onCancel={handleCancel}
        width={800}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className="px-[15px]"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="flex justify-between text-black21 text-[18px] font-normal leading-[24px] font-dmSans mb-5">
            <div className="">Filters</div>
            <div className="cursor-pointer" onClick={handleClear}>
              Clear
            </div>
          </div>
          <div className="grid grid-cols-1">
            <span className="text-black21 text-[18px] font-normal leading-[24px] font-dmSans mt-5">
              All filters
            </span>
            {filters?.map((filter, index) => (
              <div
                key={index}
                className="flex flex-col items-start mt-4 w-full"
              >
                {filter.type === "Name" && (
                  <div className="flex items-center w-full gap-x-2">
                    <Form.Item className="w-[100%]">
                      <Input
                        className="input"
                        name={`input-${index}`}
                        value={filter.value}
                        readOnly={true}
                      />
                    </Form.Item>
                    <Form.Item className="w-[100%]">
                      <Input
                        name="blkReceiverName"
                        className="input"
                        onChange={onChange}
                        value={formData?.blkReceiverName}
                        placeholder="Enter name."
                      />
                    </Form.Item>
                    <Form.Item className="w-[20%]">
                      <button onClick={() => removeFilter(index)}>
                        <img src={remove} alt="remove-icon" />
                      </button>
                    </Form.Item>
                  </div>
                )}

                {filter.type === "Commission Amount" && (
                  <div className="flex items-center w-full gap-x-2">
                    <Form.Item className="w-[100%]">
                      <Input
                        className="input"
                        name={`input-${index}`}
                        value={filter.value}
                        readOnly={true}
                      />
                    </Form.Item>
                    <Form.Item className="w-[100%]">
                      <Input
                        name="comAmount"
                        className="input"
                        onChange={onChange}
                        value={formData?.comAmount}
                        placeholder="Enter commission amount."
                      />
                    </Form.Item>
                    <Form.Item className="w-[20%]">
                      <button onClick={() => removeFilter(index)}>
                        <img src={remove} alt="remove-icon" />
                      </button>
                    </Form.Item>
                  </div>
                )}
                {filter.type === "Payment Amount" && (
                  <div className="flex items-center w-full gap-x-2">
                    <Form.Item className="w-[100%]">
                      <Input
                        className="input"
                        name={`input-${index}`}
                        value={filter.value}
                        readOnly={true}
                      />
                    </Form.Item>
                    <Form.Item className="w-[100%]">
                      <Input
                        name="blkPaymentAmount"
                        className="input"
                        onChange={onChange}
                        value={formData?.blkPaymentAmount}
                        placeholder="Enter payment amount."
                      />
                    </Form.Item>
                    <Form.Item className="w-[20%]">
                      <button onClick={() => removeFilter(index)}>
                        <img src={remove} alt="remove-icon" />
                      </button>
                    </Form.Item>
                  </div>
                )}
                {filter.type === "Mobile" && (
                  <div className="flex items-center w-full gap-x-2">
                    <Form.Item className="w-[100%]">
                      <Input
                        className="input"
                        name={`input-${index}`}
                        value={filter.value}
                        readOnly={true}
                      />
                    </Form.Item>
                    <Form.Item className="w-[100%]">
                      <Input
                        name="blkReceiverMobileNo"
                        className="input"
                        onChange={onChange}
                        value={formData?.blkReceiverMobileNo}
                        placeholder="Enter receiver Mobile No."
                        type="number"
                      />
                    </Form.Item>
                    <Form.Item className="w-[20%]">
                      <button onClick={() => removeFilter(index)}>
                        <img src={remove} alt="remove-icon" />
                      </button>
                    </Form.Item>
                  </div>
                )}
              </div>
            ))}

            <div className="flex justify-between items-center">
              <Dropdown
                overlayStyle={{
                  width: "200px",
                }}
                trigger={"click"}
                menu={{ items: settingItems }}
                placement="bottom"
              >
                <Button
                  type="btn"
                  className="flex items-center w-[150px] bg-[#A3A2A7] text-white h-[45px] py-3 px-2
                 rounded-[10px] gap-x-1 mb-10 mt-5 !text-lexendS text-[16px] font-[500]"
                >
                  <img src={svg25} alt="add-icon" />
                  Add Filter
                  <img src={svg37} alt="svg37" />
                </Button>
              </Dropdown>

              {filters && filters?.length > 0 && (
                <div className="w-[150px]">
                  <button type="submit" className="cstm-btn-2">
                    submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default FilterModal;
