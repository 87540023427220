import React from 'react'

function OrganizationStatus() {
  return (
    <div className='service-card p-5 '>
        <div className='heading_4'>Organizations</div>
        <div className='mt-[1.25rem] flex items-center gap-x-5'>
            <div className='flex items-center gap-x-2'>
                <div className='h-[14px] w-[14px] bg-[#2D7A89]'></div>
                <span>Pending Document Attachment</span>
            </div>

            <div className='flex items-center gap-x-2'>
                <div className='h-[14px] w-[14px] bg-[#BEC7E7]'></div>
                <span>Pending Approval</span>
            </div>

            <div className='flex items-center gap-x-2'>
                <div className='h-[14px] w-[14px] bg-[#2D7A89]'></div>
                <span>Approved</span>
            </div>
        </div>

        <div className="mt-[1.25rem] flex items-center">
  <div className="w-[100px] h-[100px] rounded-full bg-[#2D7A89] flex items-center justify-center z-30">
    229
  </div>
  <div className="w-[150px] h-[150px] rounded-full bg-[#BEC7E7] flex items-center justify-center -ml-[10px] z-20">
    1283
  </div>
  <div className="w-[100px] h-[100px] rounded-full bg-[#C1E0D7] flex items-center justify-center -ml-[10px] z-10">
    662
  </div>
</div>

    </div>
  )
}

export default OrganizationStatus