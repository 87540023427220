import { Form, Input, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header';
import img2 from '../../../assets/img/img2.png';
import img3 from '../../../assets/img/img3.png';
import { obfuscateEmail } from '../../../utils';
import { save, saveOtp } from '../../../features/auth/authSlice';
import toast from 'react-hot-toast';
import RightSide from '../RightSide';

function VerifyEmail() {
    const [form] = Form.useForm();
    const formRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({});
    const [isInputEmpty, setIsInputEmpty] = useState(true);

    const [counter, setCounter] = useState({ minutes: 0, seconds: 59 });
    const [activeResend, setActiveResend] = useState(false);

    const { accType, forgotAcc, saving } = useSelector((state) => state.auth);
    const resendOTPEmail = async () => {
        const res = await dispatch(
            saveOtp({
                usrId: forgotAcc?.usrId,
                url: `auth/resend-otp`,
            })
        );
        if (res?.payload?.success) {
            toast.success(res.payload?.messages?.message);
        } else {
            toast.error(res.payload?.messages?.message);
        }
    };

    const handleBack = async () => {
        await navigate('/forgot-password');
    };

    const onFinish = async (data) => {
        const res = await dispatch(
            save({
                usrId: forgotAcc?.usrId,
                usrSecret: data?.usrSecret,
                url: 'auth/validate-forgot-pwd-otp',
            })
        );
        if (res?.payload?.success) {
            toast.success(res.payload?.messages?.message);
            await navigate('/new-password');
            setIsInputEmpty(true);
            form.resetFields();
        } else {
            toast.error(res.payload?.messages?.message);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setIsInputEmpty(!value);
        setFormData((prevData) => ({
            ...prevData,
            usrFirstName: value,
        }));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (counter.minutes === 0 && counter.seconds === 0) {
                clearInterval(interval);
            } else {
                setCounter((prevCounter) => {
                    if (prevCounter.seconds === 0) {
                        return { minutes: prevCounter.minutes - 1, seconds: 59 };
                    } else {
                        return { ...prevCounter, seconds: prevCounter.seconds - 1 };
                    }
                });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [counter]);

    return (
        <>
            <div className="flex  w-full min-h-screen bg-white">
                <div className="flex flex-col w-full">
                    <Header />
                    <div className="w-full h-full flex justify-center items-center lg:px-0 px-3">
                        <div className="flex flex-col w-full justify-center items-center text-center">
                            <span className="heading_1">Verify your email</span>
                            <span className="paragraph_1 mt-[1.13rem]">We have sent an OTP to your email</span>

                            <Form
                                layout="vertical"
                                ref={formRef}
                                name="control-ref"
                                onFinish={onFinish}
                                style={{
                                    maxWidth: '100%',
                                    marginTop: '2.06rem',
                                    width: '32.25rem',
                                }}
                                form={form}
                            >
                                <div className="grid grid-cols-1">
                                    <Form.Item
                                        name="usrSecret"
                                        label={
                                            <span>
                                                Enter OTP sent to {forgotAcc?.usrEmail ? obfuscateEmail(forgotAcc?.usrEmail) : ''} <span className="text-[#FF0000]">*</span>
                                            </span>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter OTP',
                                            },
                                        ]}
                                    >
                                        <Input onChange={handleInputChange} className="input" />
                                    </Form.Item>
                                </div>

                                <div className="w-full flex flex-col items-center justify-center">
                                    <div className="flex justify-center w-[10.625rem] mt-[1.63rem]">
                                        <button
                                            className={`cstm-btn-2 ${isInputEmpty ? '!bg-[#D1D5DB]' : 'inherit'} ${isInputEmpty ? 'cursor-not-allowed' : 'inherit'}`}
                                            disabled={isInputEmpty}
                                            type="submit"
                                            key="submit"
                                        >
                                            {saving ? <Spin /> : 'Submit'}
                                        </button>
                                    </div>

                                    <div className=" justify-center flex mt-[1.19rem] w-[10.625rem]">
                                        <button type="button" className="cstm-btn " onClick={() => navigate('/forgot-password')}>
                                            Previous
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-[66px] flex items-center justify-center typography_p_2 gap-x-1">
                                    Didn’t receive OTP?
                                    {counter.minutes === 0 && counter.seconds === 0 ? (
                                        <span
                                            className="font-[600] typography_p_2 !text-[#0057E3] cursor-pointer"
                                            onClick={resendOTPEmail}
                                            disabled={counter.minutes > 0 && counter.seconds > 0}
                                            type="primary"
                                        >
                                            Resend
                                        </span>
                                    ) : (
                                        <span className="font-[600] typography_p_2">
                                            {' '}
                                            Resend in {counter.minutes.toString().padStart(2, '0')}:{counter.seconds.toString().padStart(2, '0')}
                                        </span>
                                    )}
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

                <RightSide/>
            </div>
        </>
    );
}

export default VerifyEmail;
