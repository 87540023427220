import React from 'react';
import logo from '../../assets/img/Main logo.png';
import { Progress } from 'antd';

function Header() {
    return ( 
            <div className=" px-[2.06rem] py-[1.88rem] w-full">
                <img src={logo} className="w-[7.125rem] h-[1.6875rem] object-cover" alt="image2" />
            </div> 
    );
}

export default Header;
