import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBarClosed from './SideBarClosed';
import SideBarOpen from './SideBarOpen';
import { fetchMenu } from '../features/global/globalSlice';
 
export default function SideBar() {
	const { sideMenuCollapsed } = useSelector((state) => state.global);
	const dispatch =  useDispatch()

	useEffect(() => {}, [sideMenuCollapsed]);


	useEffect(()=>{
		dispatch(fetchMenu());
	  },[])

	if (sideMenuCollapsed) return <SideBarClosed />;

	return <SideBarOpen />;
}
