import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const initialState = {
	saving: false,
};

const url = process.env.REACT_APP_API_BASE_URL;


export const save = createAsyncThunk('saveSlice/save/ordinary', async (data, { rejectWithValue }) => {
	let saveUrl = data.url;
	delete data.url;
	try {
		const response = await axiosInstance.post(`${url}/${saveUrl}`, data)
		if (!response.data.success) {
			console.log("ifpart")
			return rejectWithValue(response.data);
		} 
		return response.data;
	} catch (error) { 
		return rejectWithValue(error.response.data);
	}
  });

//   export const save = createAsyncThunk('saveSlice/saveOtp', async (data) => {
// 	let saveUrl = data.url;
// 	delete data.url;
// 	const res = await axiosInstance
// 		.post(`${url}/${saveUrl}`, data)
// 		.then((res) => res?.data);
// 	return res;
// });
  
 
export const saveSlice = createSlice({
	name: 'save',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			 
			.addCase(save.pending, (state) => {
				state.saving = true;
			})
			.addCase(save.fulfilled, (state) => {
				state.saving = false;
			})
			.addCase(save.rejected, (state) => {
				state.saving = false;
			});
	},
});

export default saveSlice.reducer;
export const {} = saveSlice.actions;
