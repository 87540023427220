import { Dropdown, Input, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import svg27 from '../../../assets/svg/svg27.svg';
import NoDataTable from '../../../components/NoDataTable';
import FilterModal from './modal/FilterModal';
import svg38 from '../../../assets/svg/svg38.svg';
import FunnelSimple from '../../../assets/svg/FunnelSimple.svg';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import { fetchWalletStatement } from '../../../features/fetch/fetchSlice';
import { dateForHumans, timeAmPm } from '../../../utils';

export default function StatementTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { open, handleCancel, handleOpen } = useModalToggle();

    const { loading, statementCount, statement } = useSelector((state) => state.fetch);
    const { walletObj } = useSelector((state) => state.obj);
 

    const [searchText, setSearchText] = useState('');
    const [prodd, setProdd] = useState();
    const [filters, setFilters] = useState([]);
    const [formData, setFormData] = useState({});
    const showModalFilter = () => {
        handleOpen();
    };

    const handleClearFilters = async () => {
        await setFormData({});
        await setFilters([]);
    };

    const settingItems = [
        {
            key: '1',
            label: (
                <Link
                    // onClick={handleEdit}
                    className="flex  text-[16px] font-sans  !text-lightBlack"
                >
                    View
                </Link>
            ),
        },
    ];
    
    const columns = [
        {
            title: 'Ref',
            dataIndex: 'transRef',
            sorter: (a, b) => a.transRef.localeCompare(b.transRef),
        },
        {
            title: 'Description',
            dataIndex: 'transDesc',
            sorter: (a, b) => a.transDesc.localeCompare(b.transDesc),
        },
        {
            title: 'Transaction Type',
            dataIndex: 'transType',
            sorter: (a, b) => a.transType.localeCompare(b.transType),
        },
        {
            title: 'After Amount',
            dataIndex: 'transAfterAmount',
            sorter: (a, b) => a.transAfterAmount - b.transAfterAmount,
        },
        {
            title: 'Amount',
            dataIndex: 'transAmount',
            sorter: (a, b) => a.transAmount - b.transAmount,
        },
        {
            title: 'Before Amount',
            dataIndex: 'transBeforeAmount',
            sorter: (a, b) => a.transBeforeAmount - b.transBeforeAmount,
        },
        {
            title: 'Running Balance',
            dataIndex: 'transRunningBalance',
            sorter: (a, b) => a.transRunningBalance - b.transRunningBalance,
        },
        {
            title: 'Created Date',
            dataIndex: 'transCreatedDate',
            render: (item) => {
                return <div>{dateForHumans(item)}</div>;
            },
            sorter: (a, b) => a.transCreatedDate - b.transCreatedDate,
        },

        {
            title: 'Created Time',
            dataIndex: 'transCreatedTime',
            render: (item) => {
                return <div>{timeAmPm(item)}</div>;
            },
            sorter: (a, b) => a.transCreatedTime - b.transCreatedTime,
        },
        {
            title: 'Credit',
            dataIndex: 'transCredit',
            sorter: (a, b) => a.transCredit - b.transCredit,
        },

        {
            title: 'Actions',
            render: (item) => (
                <>
                    <Dropdown
                        overlayStyle={{
                            width: '250px',
                        }}
                        trigger={'click'}
                        menu={{ items: settingItems }}
                        placement="bottom"
                    >
                        <button onClick={() => setProdd(item)}>
                            <img src={svg27} alt="svg27" />
                        </button>
                    </Dropdown>
                </>
            ),
        },
    ];

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);


    async function fetchWalletStatementData(page, size) {
        await dispatch(
            fetchWalletStatement({
                url:`api/v1/statement/fetch-statement`,
                // transWalUid:"7c1d3607-ac89-4f16-a73a-fd9ae257f8dc",
                transWalUid:walletObj?.walUid,
                limit: size ?? pageSize,
                start: page ?? pageIndex,
            })
        );
    }

    useEffect(() => {
        fetchWalletStatementData();
    }, []);

    return (
        <div className="w-full h-full mb-10">
            {statement && statement?.length > 0 ? (
                <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
                    <div className="flex flex-col w-full lg:px-10 px-3 ">
                        <h3 className="font-[700] text-[24px] text-lightBlack dash-title mt-[1.25rem]">Statement Details</h3>
                        <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
                            <div className="flex items-center">
                                <span>
                                    {' '}
                                    <button
                                        onClick={showModalFilter}
                                        type="button"
                                        className={`bg-transparent flex items-center gap-x-'1' ${Object?.keys(formData)?.length > 0 ? '!text-[#5688E5]' : 'inherit'}`}
                                    >
                                        <img src={FunnelSimple} alt="FunnelSimple" />
                                        Filters
                                    </button>
                                </span>
                                {Object?.keys(formData)?.length > 0 && (
                                    <span className="flex items-center text-[#5688E5] cursor-pointer ml-1">
                                        :{Object?.keys(formData)?.length}
                                        <img src={svg38} alt="svg38" onClick={handleClearFilters} />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
                            <section>
                                <Table
                                    rowSelection={false}
                                    className="mt-[1.31rem] w-full"
                                    scroll={{
                                        x: 800,
                                    }}
                                    rowKey={(record) => record?.transId}
                                    columns={columns}
                                    dataSource={statement}
                                    loading={loading}
                                    pagination={{
                                        position: ['bottomCenter'],
                                        current: pageIndex + 1,
                                        total: statementCount,
                                        pageSize: pageSize,
                                        onChange: (page, size) => {
                                            setPageIndex(page - 1);
                                            setPageSize(size);
                                            fetchWalletStatementData(page - 1, size);
                                        },
                                        showSizeChanger: false,
                                        hideOnSinglePage: true,
                                    }}
                                />
                            </section>
                        </div>
                    </div>
                </div>
            ) : (
                <NoDataTable title="" imgTitle="Statements will be displayed here once available" />
            )}

            <FilterModal isModalOpen={open} handleCancel={handleCancel} formData={formData} setFormData={setFormData} filters={filters} setFilters={setFilters} />
        </div>
    );
}
