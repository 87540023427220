import React from 'react';
import img4 from '../assets/img/img4.png';

function EmtyCard({title}) {
    return (
        <div>
            <div className="account_card_alt !w-[90%] mt-[1.69rem]">
                <div className="w-full flex flex-col justify-between items-center pointer">
                    <img className='w-[2] h-[13vh] object-cover' src={img4} alt="img4" />
                    <div className='label_1'>{title}</div>
                </div>
            </div>
        </div>
    );
}

export default EmtyCard;
