import React from 'react';
import { Breadcrumb, Dropdown } from 'antd';

export default function BreadCrumb({ breadList, header, info, btn, btnTitle, btnSvg, onClick, btnDropdown, settingItemsQuickActions }) {
    return (
        <>
            <div className="w-full flex items-center justify-between bg-white">
                <div className="flex flex-col  gap-[.75rem]">
                    <Breadcrumb separator="/" items={breadList} />
                    <span className="heading_1">{header}</span>
                    <span className="paragraph_1">{info}</span>
                </div>
                {btn && (
                    <div className="flex justify-end">
                        <button className="flex cstm-btn-2 items-center gap-x-2" type="button" onClick={onClick}>
                            <img src={btnSvg} alt="btnSvg" />
                            {btnTitle}
                        </button>
                    </div>
                )}

                {btnDropdown && (
                    <div className="flex justify-end">
                        <Dropdown
                            overlayStyle={{
                                width: '250px',
                            }}
                            trigger={'click'}
                            menu={{ items: settingItemsQuickActions }}
                            placement="bottom"
                        >
                            <div className="w-[250px]">
                                <button className="cstm-btn-2 flex gap-x-2" type="submit">
                                    <img src={btnSvg} alt={btnSvg} /> Initiate Payment
                                </button>
                            </div>
                        </Dropdown>
                    </div>
                )}
            </div>
        </>
    );
}
