import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../features/auth/authSlice';
import fetchReducer from '../features/fetch/fetchSlice';
import saveReducer from '../features/save/saveSlice';
import setupReducer from '../features/setup/setupSlice';
import objReducer from '../features/obj/objSlice';
import globalReducer from '../features/global/globalSlice'; 
import deleteReducer from '../features/delete-setting/deleteSlice'; 


const persistConfig = {
	key: 'root',
	storage,
};

const rootReducer = combineReducers({
	auth: authReducer,
    fetch: fetchReducer,
    save: saveReducer,
    setup: setupReducer,
    obj: objReducer,
    global: globalReducer, 
	setting:deleteReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export const persistor = persistStore(store);
