import React, { useRef, useState } from "react";
import Card from "./Card";
import Commission from "./Commission";
import RevenueGraph from "./RevenueGraph";
import { DatePicker, Form } from "antd";
import OrganizationStatus from "./OrganizationStatus";

function DashboardList() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const [activeBtn, setActiveBtn] = useState("All");
  const today = new Date();

  const handleFetchDayData = () => {
    //   if(activeBtn === "WEEK"){
    //     dispatch(fetchDash({
    //       msgDateFrom: getDate7DaysAgo(),
    //       msgDateTo: formatDate(today),
    //         url: "api/v2/dash",
    //       }));
    //   }
    //   if(activeBtn === "MONTH"){
    //     dispatch(fetchDash({
    //       msgDateFrom: getDate30DaysAgo(),
    //       msgDateTo: formatDate(today),
    //         url: "api/v2/dash",
    //       }));
    //   }
  };
  const handleClick = async (item) => {
    await setActiveBtn(item);
    await handleFetchDayData();
  };
  const onFinish = async () => {};
  return (
    <div className="bg-[#F6F5FB] w-full h-full overflow-y-scroll lg:p-10 p-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-14 mb-2  w-auto p-1.5">
          <button
            onClick={() => handleClick("All")}
            className={`${
              activeBtn === "All"
                ? "text-[#146ADC] border border-[#146ADC]"
                : ""
            }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
          >
            <span className="w-[7px] h-[7px] rounded-full bg-[#146ADC]"></span>{" "}
            All
          </button>
          <button
            onClick={() => handleClick("DAY")}
            className={`${
              activeBtn === "DAY"
                ? "text-[#2DD683] border border-[#2DD683]"
                : ""
            }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
          >
            <span className="w-[7px] h-[7px] rounded-full bg-[#2DD683]"></span>{" "}
            DAY
          </button>

          <button
            onClick={() => handleClick("WEEK")}
            className={`${
              activeBtn === "WEEK"
                ? "text-[#FFAB00] border border-[#FFAB00]"
                : ""
            }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
          >
            <span className="w-[7px] h-[7px] rounded-full bg-[#FFAB00]"></span>{" "}
            WEEK
          </button>
          <button
            onClick={() => handleClick("MONTH")}
            className={`${
              activeBtn === "MONTH"
                ? "text-[#9D1500] border border-[#9D1500]"
                : ""
            }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
          >
            <span className="w-[7px] h-[7px] rounded-full bg-[#9D1500]"></span>{" "}
            MONTH
          </button>
        </div>
        <div>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className=""
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <Form.Item
              name="custNationalIdentityDateOfIssuance"
              rules={[
                {
                  required: false,
                  message: "Field is required",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Card />
      <div className=" flex lg:flex-row flex-col w-full gap-5">
        <Commission />
        <RevenueGraph />
      </div>

      <div className="mt-[1.25rem] lg:w-[50%] w-full">
        <OrganizationStatus />
      </div>
    </div>
  );
}

export default DashboardList;
