import React, { useRef, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ReactPasswordChecklist from 'react-password-checklist';
import toast from 'react-hot-toast';
import { cleanForgotAcc, cleanSingleUser, save } from '../../../features/auth/authSlice';
import Header from '../Header';
import img2 from '../../../assets/img/img2.png';
import img3 from '../../../assets/img/img3.png';
import { CheckCircleOutlined } from '@ant-design/icons';
import checkBox from '../../../assets/svg/Checkbox.svg';
import RightSide from '../RightSide';

export default function NewPassword() {
    const [form] = Form.useForm();
    const formRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { saving, forgotAcc } = useSelector((state) => state.auth);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValid, setisValid] = useState(false);

    const onFinish = async () => {
        const isPasswordValid = password.length >= 8 && /\d/.test(password) && /[A-Z]/.test(password) && /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/.test(password) && password === confirmPassword;
        if (!isPasswordValid) {
            toast.error('Password does not meet the required conditions or does not match');
            return;
        }

        const res = await dispatch(
            save({
                usrId: forgotAcc?.usrId,
                usrSecret: password,
                url: 'auth/update-password',
            })
        );
        if (res?.payload?.success) {
            toast.success(res.payload?.messages?.message);
            await dispatch(cleanForgotAcc());
            await dispatch(cleanSingleUser());
            await navigate('/password-reset-success');
        } else {
            toast.error(res.payload?.messages?.message);
        }
    };

    const handleLogin = () => {
        navigate('/login');
    };

    return (
        <>
            <div className="flex  w-full h-full">
                <div className="flex flex-col w-full bg-white">
                    <Header />
                    <div className="w-full h-full flex justify-center items-center lg:px-0 px-3 ">
                        <div className="flex flex-col w-full justify-center items-center text-center">
                            <span className="heading_1">Reset Password</span>
                            <span className="paragraph_1 mt-[1.13rem]">If your email matches records on our system you will receive a notifcation on steps to reset your passsword</span>

                            <Form
                                layout="vertical"
                                ref={formRef}
                                name="control-ref"
                                onFinish={onFinish}
                                style={{
                                    maxWidth: '100%',
                                    marginTop: '2.06rem',
                                    width: '32.25rem',
                                }}
                                form={form}
                            >
                                <Form.Item
                                    label="Password"
                                    name="usrEncryptedPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password',
                                        },
                                    ]}
                                >
                                    <Input.Password onChange={(e) => setPassword(e.target.value)} className="input" type="password" />
                                </Form.Item>

                                <Form.Item
                                    label="Confrim password"
                                    name="usrEncryptedPasswordAlt"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password',
                                        },
                                    ]}
                                >
                                    <Input.Password onChange={(e) => setConfirmPassword(e.target.value)} className="input" type="password" />
                                </Form.Item>

                                <div className="text-left">
                                    <span className="paragraph_3 !text-[#616161]">Password must contain:</span>
                                    <ReactPasswordChecklist
                                        className="text-darkBlue"
                                        rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                                        minLength={8}
                                        value={password}
                                        valueAgain={confirmPassword}
                                        onChange={(isValid) => {
                                            setisValid(isValid);
                                        }}
                                        messages={{
                                            minLength: 'A minimum of 8 characters',
                                            specialChar: 'At least one symbol (~!@$&)',
                                            number: 'At least one number (0-9)',
                                            capital: 'At least one upper case letter (A-Z)',
                                            match: 'Confirm password to match with the password',
                                        }}
                                        iconComponents={{
                                            ValidIcon: <CheckCircleOutlined className="text-[1rem] mr-[.5rem] text-[green]" />,
                                            InvalidIcon: <img className="mr-[.5rem]" src={checkBox} alt="checkBox" />,
                                        }}
                                    />
                                </div>
                                <div className="w-full flex flex-col items-center justify-center">
                                    <div className="flex justify-center w-[10.625rem] mt-[1.63rem]">
                                        <button className="cstm-btn-2" type="submit" disabled={saving ? true : false}>
                                            {saving ? <Spin /> : 'Submit'}
                                        </button>
                                    </div>

                                    <div className="w-[10.625rem] justify-center flex mt-[1.19rem]">
                                        <button type="button" className="cstm-btn" onClick={handleLogin}>
                                            Back to Login
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

                <RightSide/>
            </div>
        </>
    );
}
